export default [
    {
        label: 'No.',
        field: 'orderNo',
        sort: true
    },
    {
        label: '발급일자',
        field: 'createdAt',
        sort: true
    },
    {
        label: '비행일자',
        field: 'flightDate',
        sort: true
    },
    {
        label: '문서번호',
        field: 'index',
        sort: true
    },
    {
        label: '',
        field: 'action',
        sort: false
    }
];
