<template>
    <div class="main-container">
      <h5 class="pageTitle" v-html="pageTitle"></h5>
      <mdb-container class="search-container">
        <mdb-row>
          <div class="ml-3">
            <mdb-select
                class="search-select"
                outline
                resetBtn
                v-model="selectOptions"
                placeholder="검색 항목"
                @getValue="getSelectValue"/>
          </div>
          <mdb-col>
            <mdb-input type="text" v-model="search" outline placeholder="검색어를 입력하세요."></mdb-input>
          </mdb-col>
        </mdb-row>
      </mdb-container>
      <mdb-container>
        <mdb-datatable-2
            v-model="data"
            class="text-center"
            striped
            bordered
            hover
            :searching="{value: search, field: fieldName} "            
            noFoundMessage="데이터가 없습니다."/>        
      </mdb-container>
    </div>
  </template>

<script>
import {mdbCol, mdbContainer, mdbDatatable2, mdbInput, mdbRow, mdbSelect} from "mdbvue";
import log_view_list from "@/assets/data/columns/log_view_list";
import axios from "@/axios";

export default {
    name: "NonUserList",
    components: {
        mdbContainer,
        mdbDatatable2,
        mdbRow,
        mdbCol,
        mdbInput,
        mdbSelect,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        pageTitle: '기체비행기록부 목록',
        data: {
          columns: log_view_list,
          rows: []
        },
        search: '',
        fieldName: '',
        selectOptions: [
          {text: "비행일자", value: "flightDate"},
          {text: "문서번호", value: "index"},          
        ],        
      }
    },
    created() {
        window.onSelected = this.onSelected;
    },
    mounted() {
      this.init();
    },    
    methods: {
      init() {
        this.getList()
      },
      formatDate(date) {
        const year = date.getFullYear() % 100;
        const month = ('0' + (date.getMonth()+1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);

        return `${year}.${month}.${day}`;
      },
      formatDate2(date) {
        const year = date.getFullYear() % 100;
        const month = ('0' + (date.getMonth()+1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const hours = ('0' + date.getHours()).slice(-2);
        const minutes = ('0' + date.getMinutes()).slice(-2);
        const seconds = ('0' + date.getSeconds()).slice(-2);

        return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
      },
      getList() {
        const self = this;
        self.data.rows.splice(0);   //data.rows 배열을 비움
        let orderNo = 0;

        const config = {
            method: 'get',
            url: '/logrecord/all',
            headers: {
            'Content-Type': 'application/json',
            },
        };

        axios(config)
            .then((querySnapshot) => {
                if (querySnapshot.data.size === 0) {
                    return
                }
                querySnapshot.data.forEach((doc) => {                   
                    orderNo++;
                    doc['orderNo'] = orderNo;
                    const issueDate = this.formatDate2(new Date(doc.createdAt));
                    const flightDate = this.formatDate(new Date(doc.flightDate));
                    doc['createdAt'] = issueDate;
                    doc['flightDate'] = flightDate;
                    doc['action'] = '<button class="btn-detail" onclick="onSelected(\'' + flightDate + '\', \'' + doc.index + '\')">조회</button>';
                    self.data.rows.push(doc)                    
                })
            })
      },
      onSelected(date, index) {
        const self = this;        
        const selData = self.data.rows.find((element) => element.index.toString() === index.toString() && element.flightDate === date);
        delete selData.action;
        self.$router.push({name: 'logbookmodify', params: {logBookInfo: selData, source: 'reissue'}});
      },
      getSelectValue(value) {
        const self = this;
        self.fieldName = value
      },
    }
  }
  </script>
  
  <style scoped>
  .main-container {
    padding: 15px;
  }
  
  .pageTitle {
    font-weight: bold;
  }
  
  .search-select {
    width: 150px;
  }

  /deep/ .btn-detail {
    border: 1px solid #2e56f4;
    background: #2e56f4;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
    padding: 0 16px;
  }
  </style>
  